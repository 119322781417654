@charset 'utf-8';

@use '../../foundation/global/_global' as *;

.is-md-show {
  display: none;
  @include max-screen($breackpoint_md) {
    display: block;
  }
}

.is-md-hide {
  @include max-screen($breackpoint_md) {
    display: none;
  }
}
