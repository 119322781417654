@charset 'utf-8';

// 個人のmixin
// @forward 'karoji';
// @forward 'koyanagi';
// @forward 'tanaka';

@use '../variables' as *;

// メディアクエリ
// **********************************************
@mixin max-screen($breakPoint) {
  @media (max-width: $breakPoint) {
    @content;
  }
}
@mixin min-screen($breakPoint) {
  @media (min-width: $breakPoint) {
    @content;
  }
}
@mixin screen($breakPoint_min, $breakPoint_max) {
  @media (min-width: $breakPoint_min) and (max-width: $breakPoint_max) {
    @content;
  }
}
