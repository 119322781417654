@charset 'utf-8';

@use 'global/_global' as *;

body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

main {
  display: block;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

address {
  font-style: normal;
}

sup {
  font-size: smaller;
  vertical-align: super;
}
