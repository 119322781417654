@charset 'utf-8';

@use '../../foundation/global/_global' as *;

// 投稿の本文

.p-post-style {
  $parent: &;
  font-size: 16px;
  > * + * {
    margin-top: 2em;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.5;
  }
  h2 {
    margin-top: 2em;

    font-size: 1.5em;
  }
  h3 {
    margin-top: 2em;

    font-size: 1.3em;
  }
  h4,
  h5,
  h6 {
    margin-top: 2em;

    font-size: 1.15em;
  }
  ul,
  ol {
    padding-left: 1.25em;
    @at-root #{$parent} ul > li {
      list-style: disc outside;
    }
    @at-root #{$parent} ol > li {
      list-style: decimal outside;
    }
  }
  a {
    color: $c--link;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  b,
  strong {
    font-weight: bold;
  }
  blockquote {
    padding: 1em;
    font-size: 0.8em;
    background-color: #eee;
  }
  iframe {
    max-width: 100%;
  }
  img {
    display: block;
    width: initial;
    margin: auto;
  }
}
