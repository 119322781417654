@charset 'utf-8';

@use 'global/_global' as *;

body {
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
  font-size: 16px;
  line-height: 1.7;
  color: $c--text;
  word-break: break-all;
}
